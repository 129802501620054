@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary-color: #fd614b;
	--header-text: #001439;
	--body-text: #50607b;
	--body-text-2: #434c5b;
}

@layer components {
	@keyframes loader {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.btn-loader:disabled::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: 2px solid;
		border-top-color: white;
		-webkit-animation: loader 0.8s linear infinite;
		animation: loader 0.8s linear infinite;
	}

	.btn-loader:disabled {
		position: relative;
		color: var(--primary-color) !important;
	}

	.primary-btn {
		border: 1px solid var(--primary-color);
		padding: 1.5rem 3rem;
		border-radius: 3rem;
		background-color: var(--primary-color);
		color: white;
		font-family: Roobert;
		font-size: 1.6rem;
		line-height: 2rem;
		font-weight: 500;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		transition: all 0.5s ease;
		min-width: fit-content;
	}

	.primary-btn:hover {
		background-color: transparent;
		color: var(--primary-color);
	}

	.secondary-btn {
		border: 1px solid var(--primary-color);
		padding: 1.5rem 3rem;
		border-radius: 3rem;
		color: var(--primary-color);
		font-family: Roobert;
		font-size: 1.6rem;
		line-height: 17.08px;
		letter-spacing: -1%;
		font-weight: 500;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		transition: all 0.5s ease;
		min-width: fit-content;
	}

	.secondary-btn:hover {
		background-color: var(--primary-color);
		color: white;
	}

	.cancel-btn {
		padding: 1.5rem 3rem;
		border-radius: 3rem;
		color: #1f1d1d;
		font-family: Roobert;
		font-size: 1.6rem;
		line-height: 17.08px;
		letter-spacing: -1%;
		font-weight: 500;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		transition: all 0.5s ease;
		min-width: fit-content;
	}

	.dashboard-header {
		@apply font-Roobert font-semibold text-[2.4rem] md:text-[3rem] text-headerText;
	}
}

@layer base {
	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 300;
		src: url("./assets/fonts/RoobertRegular.ttf") format("truetype");
	}

	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 400;
		src: url("./assets/fonts/RoobertRegular.ttf") format("truetype");
	}

	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 500;
		src: url("./assets/fonts/RoobertMedium.ttf") format("truetype");
	}

	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 600;
		src: url("./assets/fonts/RoobertSemiBold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 700;
		src: url("./assets/fonts/RoobertBold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Roobert";
		font-display: swap;
		font-weight: 800;
		src: url("./assets/fonts/RoobertHeavy.ttf") format("truetype");
	}
}

html {
	font-size: 62.5%;
}

#root {
	max-width: 1960px;
	margin-inline: auto;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

ul[role="list"],
ol[role="list"] {
	list-style: none;
}

html:focus-within {
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	font-size: 1.6rem;
	font-family: "Plus Jakarta Sans", sans-serif;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img,
picture {
	max-width: 100%;
	display: block;
}

input,
button,
textarea,
select {
	font: inherit;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
	border-color: var(--primary-color) !important;
	box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

button:disabled {
	cursor: not-allowed;
}

.modal {
	margin-top: 50px;
	height: 800px;
}

.app-modal-content {
	border-radius: 10px;
	background: #fff;
	border: none;
	margin: 0 auto;
}

.modal-header {
	border-bottom: none;
	font-style: normal;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.6rem;
	color: var(--primary-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #ebebeb;
	padding: 5px 30px;
}

.modal-header::before,
.modal-header::after {
	display: none;
}

.modal-header h4.modal-title {
	font-size: 1.6rem;
	color: var(--primary-color);
}

.modal-header .close {
	padding: 10px;
	background-color: transparent;
	border: none;
	font-size: 2.4rem;
}

.rec-modal-body {
	isolation: isolate;
	padding: 3rem;
}

.modal-xlg {
	max-width: 1200px !important;
}

.modal-lg {
	max-width: 900px !important;
}

.modal-sm {
	max-width: 500px !important;
}
.modal-normal {
	max-width: 700px !important;
}

.modal-xsm {
	max-width: 400px !important;
}

.modal-xsm .modal-title {
	font-size: 0.5rem;
}

.modal-subtitle {
	margin-bottom: 30px;
	margin-top: -20px;
	font-size: 14px;
	font-family: inherit;
	color: #5e6c85;
}

.modal-dialog {
	width: 97vw;
	max-height: 80vh;
}

.ReactModal__Overlay--after-open {
	z-index: 1000;
	background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content--after-open {
	border: none !important;
	background: transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
	inset: 0 !important;
}

.popup-content {
	padding: 0;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 3px 33px 5px rgb(22 49 96 / 19%);
	z-index: 1000 !important;
}

.popup-option {
	padding: 8px 15px;
	font-weight: 400;
	font-size: 1.4rem;
	position: relative;
	overflow: hidden;
	display: flex;
	gap: 5px;
	align-items: center;
}

.ps-menuitem-root {
	margin-bottom: 2px;
	border-radius: 5px;
	overflow: hidden;
}

.ps-menu-icon svg {
	width: 23px !important;
	height: 23px !important;
}

.ps-menu-root {
	padding-inline: 1.5rem;
}

.ps-menu-button {
	padding: 10px 15px !important;
	font-weight: 500;
	font-size: 1.6rem;
	height: auto !important;
}

.ps-menu-label {
	color: var(--body-text-2);
}

.ps-menu-icon {
	color: var(--body-text-2);
}

.ps-menu-label.ps-active {
	color: var(--primary-color);
}

.ps-menu-icon.ps-active {
	color: var(--primary-color);
}

.ps-menu-button:hover {
	background-color: #fdf3f0 !important;
	color: var(--primary-color) !important;
}

.ps-menu-button:hover .ps-menu-label,
.ps-menu-button:hover .ps-menu-icon {
	color: var(--primary-color) !important;
}

.ps-menu-button.ps-active {
	background-color: #fdf3f0 !important;
	color: var(--primary-color) !important;
}

.custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;

	background: transparent !important;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0Ljk0MDEgNi43MTI1MkwxMC4wNTAxIDExLjYwMjVDOS40NzI1NiAxMi4xOCA4LjUyNzU2IDEyLjE4IDcuOTUwMDYgMTEuNjAyNUwzLjA2MDA2IDYuNzEyNTIiIHN0cm9rZT0iIzQ4NTA1RiIgc3Ryb2tlLXdpZHRoPSIxLjMiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") !important;
	background-repeat: no-repeat !important;
	background-position-x: 97% !important;
	background-position-y: 50% !important;
}

.full-bleed {
	box-shadow: 0 0 0 100vmax var(--bleed-color);
	clip-path: inset(0 -100vmax);
}

.custom-pagination {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.custom-pagination-page {
	width: 30px;
	aspect-ratio: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--gray);
}

.custom-pagination-page.selected {
	background-color: var(--primary-color);
	color: white;
}

.custom-pagination-previous,
.custom-pagination-next {
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	line-height: 2rem;
	font-weight: 500;
	border: 1px solid var(--lightGray);
	color: var(--body-text-2);
}

.szh-accordion__item-btn {
	width: 100%;
}

.szh-accordion__item-btn:disabled {
	cursor: auto;
}

.szh-accordion__item-heading {
	border-left: 1px solid #919baf66;
	border-right: 1px solid #919baf66;
	border-bottom: 1px solid #919baf66;
	border-top: 6px solid var(--primary-color);
	border-radius: 10px;
	padding-block: 8px;
	padding-inline: 12px;
}

.szh-accordion__item-heading.random {
	padding: 24px;
	border: none;
}

.szh-accordion__item.random {
	border: 1px solid #919baf66;
	border-radius: 10px;
}

.szh-accordion__item-panel {
	padding-block: 12px;
	padding-inline: 12px;
	display: grid;
	gap: 25px;
}

.szh-accordion__item-panel.random {
	padding: 0px 24px 24px;
	display: grid;
	gap: 25px;
}

.szh-accordion {
	display: grid;
	gap: 20px;
}

.public-DraftStyleDefault-block {
	margin: 0 !important;
}

.truncate-overflow {
	position: relative;
	max-height: calc(var(--line-height) * var(--max-lines));
	overflow: hidden;
	padding-right: 1rem;
}

.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.timeline:last-of-type::before {
	display: none;
}

[data-radix-popper-content-wrapper] {
	z-index: 5000000000000000 !important;
}

@media (max-width: 800px) {
	.slick-track img {
		height: 170px;
		aspect-ratio: 1;
		object-fit: contain;
	}
}

@media (min-width: 800px) {
	.slick-list {
		height: 100% !important;
	}

	.slick-track {
		width: 200px;
	}
}

@media (max-width: 768px) {
	html {
		font-size: 58.5%;
	}

	.mob-display > a,
	.mob-display > button {
		padding: 1.25rem 2rem;
		font-size: 1.8rem;
		font-weight: 500;
	}

	.rec-modal-body {
		padding: 1.5rem;
	}
}

@media (min-width: 955px) and (max-width: 1400px) {
	.last-impact {
		grid-column-start: 2;
		grid-column-end: 3;
	}
}
